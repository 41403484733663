import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Container, ContentSection, SplitConfig } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { Button } from "@ryerson/frontend.button";
import { CustomizedMetalSolutionsProps } from "../CustomizedMetalSolutions";
import { navigate } from "gatsby";
import { Link } from "@ryerson/frontend.navigation";

const Divider = styled.hr`
	opacity: 0.1;
	margin-top: 69px;
	margin-left: 2px;
	max-width: 1160px;
	width: 100%;
	${(props: any) => {
		const { theme } = props;
		return css`
			border-top: 1px solid ${theme.colors.primary.white};
		`;
	}}
`;

const BigH1 = styled.div`
	font-size: 150px;
	line-height: 140px;
	width: 100%;
	letter-spacing: -6pt;
	margin-top: -20px;
	margin-left: -10px;
	${(props: any) => {
		const { theme } = props;
		return css`
			font-family: ${theme.typography.fontFamilies.headings};
			color: ${theme.colors.primary.white};
		`;
	}}
`;

const ContactContainer = styled.div`
	height: auto;
	width: 400px;
	display: inline-block;
	vertical-align: middle;
	text-align: left;
	${(props: any) => {
		const { theme } = props;
		return css`
			font-family: ${theme.typography.fontFamilies.primary};
			color: ${theme.colors.primary.lightGray};
		`;
	}}
`;

const MainDescriptionContainer = styled.div`
	height: auto;
	width: 560px;
	display: inline-block;
	vertical-align: middle;
`;

const SubHeaderContainer = styled.div`
	margin-top: 69px;
	display: block;
`;

const ContactPortrait = styled.div`
	width: 80px;
	height: 80px;
	border-radius: 80px;
	background-size: cover;
	background-position: center center;
	display: block;
	margin-bottom: 31px;
	${(props: any) => {
		return css`
			background-image: url(${props.contact.portrait});
		`;
	}}
`;

const ContactLineInformation = styled.div`
	margin-top: 9px;
	display: block;
`;

const ThingLinkContainer = styled.div`
	margin-top: 111px;
	width: 100%;
	height: auto;
	display: block;
	margin-bottom: 111px;
`;

const SecondaryContentContainer = styled.div`
	width: 100%;
	display: block;
`;

const SecondaryDescription = styled.div`
	width: 620px;
	display: inline-block;
	vertical-align: top;
`;

const ContactAnExpertButton = styled.div`
	width: calc(100% - 620px);
	display: inline-block;
	text-align: right;
	vertical-align: top;
`;

const CustomizedMetalSolutionsDesktop: React.FC<CustomizedMetalSolutionsProps> = (props) => {
	const { theme } = useTheme();

	const isWindow = typeof window !== "undefined";
	const [frameWidth, setFrameWidth] = React.useState(0);
	const [frameHeight, setFrameHeight] = React.useState(0);
	const { white } = theme.colors.primary;
	const { lightGray } = theme.colors.primary;
	const originalWidth = props.thinglink.originalWidth;
	const originalHeight = props.thinglink.originalHeight;
	const aspect = originalHeight / originalWidth;

	React.useEffect(() => {
		if (isWindow) {
			if (document && document.getElementById("thinglink_container_id") !== null) {
				let width = document.getElementById("thinglink_container_id")?.offsetWidth;
				if (width) {
					setFrameWidth(width);
					let height = width * aspect;
					setFrameHeight(height);
				}
			}
			window.addEventListener("resize", () => {
				if (document && document.getElementById("thinglink_container_id") !== null) {
					let width = document.getElementById("thinglink_container_id")?.offsetWidth;
					if (width) {
						setFrameWidth(width);
						let height = width * aspect;
						setFrameHeight(height);
					}
				}
			});
		}
	}, []);

	const split: SplitConfig = {
		splitDirection: "vertical",
		splitColors: [
			{
				color: theme.colors.primary.darkerGray,
				percentage: "100%",
			},
		],
	};

	return (
		<ContentSection vPadding="140px" split={split} type="split">
			<Container>
				<Typography
					color={lightGray}
					variant="h2"
					css={css`
						margin-bottom: 30px;
					`}
				>
					{props.sectionHeading}
				</Typography>
				<BigH1 theme={theme}>{props.title}</BigH1>
				<Divider theme={theme} />
				<SubHeaderContainer>
					<ContactContainer theme={theme}>
						<ContactPortrait {...props} />
						<ContactLineInformation>
							<Typography weight="bold" color={white}>
								{props.contact.name}
							</Typography>
						</ContactLineInformation>
						<ContactLineInformation>
							<Typography type="tertiary">{props.contact.title}</Typography>
						</ContactLineInformation>
						<ContactLineInformation>
							<Typography type="tertiary">
								<Link
									gatsby={false}
									to={`mailto:${props.contact.email}`}
									color={theme.colors.primary.lightGray}
								>
									{props.contact.email}
								</Link>
							</Typography>
						</ContactLineInformation>
					</ContactContainer>
					<MainDescriptionContainer>
						<Typography color={lightGray} variant="h4" size="lg">
							{props.mainDescription}
						</Typography>
					</MainDescriptionContainer>
				</SubHeaderContainer>
				<ThingLinkContainer id="thinglink_container_id">
					<iframe
						width={frameWidth}
						height={frameHeight}
						data-original-width={props.thinglink.originalWidth}
						data-original-height={props.thinglink.originalHeight}
						style={{ border: "none" }}
						scrolling="no"
						src={"https://www.thinglink.com/card/" + props.thinglink.id}
					></iframe>
				</ThingLinkContainer>
				<SecondaryContentContainer>
					<SecondaryDescription>
						<Typography variant="div" size="md" color={lightGray}>
							{props.thinglink.description}
						</Typography>
					</SecondaryDescription>
					<ContactAnExpertButton>
						<span style={{ display: "inline-block" }}>
							<Button
								onClick={() => {
									navigate("/contact-us#productQuestion");
								}}
								size="xl"
								label={props.contact.button}
							/>
						</span>
					</ContactAnExpertButton>
				</SecondaryContentContainer>
			</Container>
		</ContentSection>
	);
};
export default CustomizedMetalSolutionsDesktop;
